import { CreditCheckReportDto } from '../../types';
import { creditCheckSearchStyles } from './credit-score-report.styles';
import { CreditCheckResultEnum, CreditCheckResultTitles } from './credit-score-report.types';

interface Props {
  report?: CreditCheckReportDto;
  isBlurred?: boolean
}

const CreditCheckReport = ({ report, isBlurred = false }: Props) => {
  const getStatusStyles = (status?: CreditCheckResultEnum) => {
      if (!status) return creditCheckSearchStyles[CreditCheckResultEnum.NA];
      return isBlurred ? creditCheckSearchStyles[CreditCheckResultEnum.NA] : creditCheckSearchStyles[status];
    };

  return (
    <div>
      <h4 className='text-xl font-bold leading-[2.25] py-2 border-b border-b-[1px] border-[#f0f0f0] px-5'>Credit Report</h4>
      <div className='flex justify-between py-2 border-b border-b-[1px] border-[#f0f0f0] px-5'>
        <p className='leading-[2.25]'>TransUnion Credit Report</p>
        <p className={getStatusStyles(report?.result)}>{report?.result ? CreditCheckResultTitles[report?.result] : 'N/A'}</p> 
      </div>
    </div>
  )
}

export default CreditCheckReport