import { UndefinedInitialDataOptions, useMutation, UseMutationOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { AuthRole } from "common/enums/enums";
import { QueryKey } from "common/enums/query-key";
import { BackendError } from "common/types/errors/backendError";
import { MasterAgreementsDto, MasterAgreementsRequestDto, SentLeaseConsentDto, SentLeaseDto } from "components/templates/MasterAgreement/MasterAgreementsTable/types/master-agreements-table.type";
import { useHasRoles } from "hooks/use-has-roles.hook";
import { getLandlordMasterAgrements, getMasterAgrements, getSentLeases, signSentLeases } from "services/api/lease-documents";
import { Notification } from 'services/notification.service';

export default function useMasterAgreements(
  params: MasterAgreementsRequestDto,
  options?: Omit<
    UndefinedInitialDataOptions<MasterAgreementsDto, Error, MasterAgreementsDto, (string | MasterAgreementsRequestDto)[]>,
    'queryFn' | 'queryKey'
  >,
) {
  const isLandlord = useHasRoles([AuthRole.Landlord]);
  return useQuery({
    queryKey: [QueryKey.MasterAgreements, 'renter', params],
    queryFn: () => isLandlord ? getLandlordMasterAgrements(params) : getMasterAgrements(params),
    ...options,
  });
}

export function useGetSentLeaseDocuments(
  applicationId: string,
  options?: Omit<
    UndefinedInitialDataOptions<SentLeaseDto[], Error, SentLeaseDto[]>,
    'queryFn' | 'queryKey'
  >,
) {
  return useQuery({
    queryKey: [QueryKey.SentLeaseDocuments, applicationId],
    queryFn: () => getSentLeases(applicationId),
    ...options,
  });
}

export function useSignSentLeases(options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, SentLeaseConsentDto, unknown>, 'mutationFn'>) {
  const queryClient = useQueryClient();
  
  return useMutation({
    ...options,
    mutationFn: signSentLeases,
    onError: (e) => {
      const notification = new Notification();
      notification.error(e.message || 'Sorry, an error occurred');
    },
    onSuccess: () => {
      const notification = new Notification();
      notification.success('The lease documents have been accepted.');
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.MasterAgreement, variables.applicationId] });
    }
  });
}
