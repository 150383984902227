import clsx from 'clsx'
import { OfferStatusDto } from 'common/enums/services/api/offers/offer-status-dto.enum'
import { OffersItemDto } from 'common/types/services/api/offers/offer-item-dto.type'
import SendLeaseModal from 'components/organisms/Landlord/SendLeaseModal/SendLeaseModal'
import { RejectOfferModalByLandlordModal } from 'components/organisms/RejectOfferModalByLandlord/RejectOfferModalByLandlord'
import { getUserOfferStatuses } from 'helpers/get-user-offer-statuses'
import { useAuth } from 'hooks'
import { useAcceptOffer, useRejectOffer } from 'hooks/query'
import { useUnverifiedProfileActionGuard } from 'hooks/use-unverified-profile-guard'
import { Button } from 'legacy-components/componets'
import React, { useState } from 'react'
import { notification } from 'services/services'

interface Props {
  applicationId: string;
  offerDetails: OffersItemDto,
  onRequestBackgroundCheck: () => void;
}

const ApplicationSummaryStatusActions = ({ offerDetails, applicationId, onRequestBackgroundCheck }: Props) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openSendLeaseModal, setOpenSendLeaseModal] = useState(false);
  const { user } = useAuth();
  const offerStatuses = getUserOfferStatuses(user);
  
  const { withActionGuard } = useUnverifiedProfileActionGuard({
    modalProps: {
      actionText: 'accept or reject an offer',
    },
  });

  const { mutateAsync: acceptOffer } = useAcceptOffer({
    onError: (e) => {
      notification.error(e?.response?.data.detail || 'Offer cannot be accepted, try again later.');
    },
  });

  const { mutateAsync: rejectOffer, isPending: isRejectionPending } = useRejectOffer({
    onError: (e) => {
      notification.error(e?.response?.data.detail || 'Offer cannot be rejected, try again later.');
    },
  });
  
  const handleAccept = React.useCallback(async () => {
    if (!offerDetails) return;
    await acceptOffer(offerDetails.id);
  }, [offerDetails, withActionGuard]);

  const handleReject = React.useCallback(async () => {
    if (!offerDetails) return;
    setOpenRejectModal(true);
  }, [offerDetails, withActionGuard]);

  const handleOpenLeaseModal = React.useCallback(() => {
    if (!offerDetails) return;
    setOpenSendLeaseModal(true);
  }, [offerDetails, withActionGuard]);

  // TODO: need to refactor after clarification. We need to know which statuses of Offer allow us to perform Accept/Reject operations (Page 21 in .ppx)
  const displayAcceptRejectButtons = offerDetails.status === OfferStatusDto.PreApplicationPaid 
    || offerDetails.status === OfferStatusDto.BackgroundCheckRequested
    || offerDetails.status === OfferStatusDto.BackgroundCheckInitiated
    || offerDetails.status === OfferStatusDto.BackgroundCheckCompleted
    || offerDetails.status === OfferStatusDto.CreditCheckCompleted;

  return (
    <>
    <RejectOfferModalByLandlordModal
        isOpen={openRejectModal}
        isSubmitting={isRejectionPending}
        onClose={() => setOpenRejectModal(false)}
        onSubmit={rejectOffer}
        offerId={applicationId}
      />
      {openSendLeaseModal && 
      <SendLeaseModal
        isOpen={openSendLeaseModal}
        onClose={() => setOpenSendLeaseModal(false)}
        applicationId={applicationId}
        renterId={offerDetails.renterId}
      />}
      <div className='button-group flex-wrap flex gap-6 items-center'>
      <div className={'flex items-center gap-3'}>
        <span className={'font-bold'}>Status: </span>
        <span
          className={clsx(
            'inline-block text-white rounded px-2 py-1',
            offerDetails.status === OfferStatusDto.Accept && 'bg-green-400',
            offerDetails.status === OfferStatusDto.PreApplicationAccepted && 'bg-green-400',
            offerDetails.status === OfferStatusDto.Reject && 'bg-red-400',
            offerDetails.status === OfferStatusDto.PreApplicationPaid && 'bg-orange-400',
            offerDetails.status === OfferStatusDto.CreditCheckCompleted && 'bg-orange-400',
            offerDetails.status === OfferStatusDto.PaymentInProcess && 'bg-orange-400',
            offerDetails.status === OfferStatusDto.Paid && 'bg-primary',
            offerDetails.status === OfferStatusDto.Cancel && 'bg-trueGray bg-opacity-60',
            offerDetails.status === OfferStatusDto.PendingPayment && 'bg-yellow-400',
            offerDetails.status === OfferStatusDto.BackgroundCheckRequested && 'bg-yellow-400',
            offerDetails.status === OfferStatusDto.IncompleteOffer && 'bg-orange-400',
            offerDetails.status === OfferStatusDto.WaitingAllSignature && 'bg-yellow-400',
            offerDetails.status === OfferStatusDto.LeaseSigned && 'bg-green-400',
            offerDetails.status === OfferStatusDto.LandlordCountersignedLease && 'bg-green-400',
          )}
        >
          {offerStatuses[offerDetails.status]}
        </span>
        </div>

        <div className='flex gap-6 flex-wrap items-center'>
          {offerDetails.status === OfferStatusDto.PreApplicationPaid && <Button
            onClick={() => withActionGuard(() => onRequestBackgroundCheck())}
            label={'Request Background Check'}
            theme={'primary'}
            icon={{ name: 'circle-checked', width: 20, height: 20 }}
          />}
          {displayAcceptRejectButtons && <Button
            onClick={() => withActionGuard(() => handleOpenLeaseModal())}
            label={'Send lease'}
            theme={'primary'}
            icon={{ name: 'circle-checked', width: 20, height: 20 }}
          />}
          {displayAcceptRejectButtons && <Button
            onClick={() => withActionGuard(() => handleAccept())}
            label={'Accept'}
            theme={'primary'}
            icon={{ name: 'circle-checked', width: 20, height: 20 }}
          />}
          {displayAcceptRejectButtons && <Button
            onClick={() => withActionGuard(() => handleReject())}
            label={'Reject'}
            theme={'red'}
            icon={{ name: 'times-circle', width: 20, height: 20 }}
          />}
        </div>
      </div>
    </>
  )
}

export default ApplicationSummaryStatusActions