import { EstateTypeDto, OfferStatusDto } from "common/enums/enums";
import { PropertyCellProps } from "../../../../../legacy-pages/renter/profile/renter-profile/common/components/table/renter-table.types";
import { PaginationDtoRequest } from "common/types/types";
import { SortOrder } from "common/types/sort-order";

export type MasterAgreementAction = () => void;

export type MasterAgreementActionsCellProps = {
  onViewMasterAgreement: MasterAgreementAction;
  onViewAttachedLeaseDocuments: MasterAgreementAction;
  id: string;
  status: OfferStatusDto;
  viewLeaseDocuments: boolean;
};

export enum TableMode {
  Renter = 'renter',
  Landlord = 'landlord'
};

export type MasterAgreementTableRow = {
  date: Date;
  property: PropertyCellProps;
  userName: string;
  proposedRent: string;
  status: OfferStatusDto;
  actions: MasterAgreementActionsCellProps;
};

export enum MasterAgreementTableAccessor {
  Date = 'date',
  Property = 'property',
  Landlord = 'landlord',
  UserName = 'userName',
  ProposedRent = 'proposedRent',
  Status = 'status',
  Actions = 'actions',
};

export type MasterAgreementsDto = {
  items: MasterAgreementTableDto[];
  totalCount: number;
}

export type MasterAgreementTableDto = {
  id: string;
  applicationId: string;
  estateId: string;
  landLordName: string;
  renterName: string;
  updatedAt: Date;
  estateAddress: string;
  unitNumber: string;
  proposedRent: number;
  price: number;
  thumbnailUrl: string;
  applicationStatus: OfferStatusDto;
  estateType: EstateTypeDto;
  hasSentLeases: boolean;
}

export type MasterAgreementsRequestDto = PaginationDtoRequest & {
  sorters?: { updatedAt?: SortOrder };
  page?: number;
  perPage?: number;
};

export type SentLeaseDto = {
  sentLeaseId: string;
  fileId: string;
  fileName: string;
  fileUrl: string;
  title: string;
  isChecked?: boolean;
}

export type SentLeaseConsentDto = {
  applicationId: string;
}