import { MasterAgreementActionsCellProps, MasterAgreementTableRow } from '../types/master-agreements-table.type'
import { CellProps } from 'react-table'
import { DropdownMenuItem } from 'legacy-components/dropdown-menu/common/types/dropdown-menu-item';
import { DropdownMenu, Icon } from 'legacy-components/componets';
import Dropdown from 'rc-dropdown';
import { LEASE, VIEW_LEASE_DOCUMENTS, VIEW_MASTER_AGREEMENT } from '../constants/master-agreements-table.constants';

const MasterAgreementActionCell = ({ value }: CellProps<MasterAgreementTableRow, MasterAgreementActionsCellProps>) => {
  // NOTE: we could handle actions visibility based on table mode: 'renter' | 'landlord'
  const { onViewMasterAgreement, onViewAttachedLeaseDocuments, id, viewLeaseDocuments } = value;
  const menuItems: DropdownMenuItem[] = [
    {
      id: 0,
      title: VIEW_MASTER_AGREEMENT,
      show: true,
      onClick: () => onViewMasterAgreement(),
      icon: 'faEye',
    },
    {
      id: 1,
      title: VIEW_LEASE_DOCUMENTS,
      show: viewLeaseDocuments,
      onClick: () => onViewAttachedLeaseDocuments(),
      icon: 'file',
    },
  ].filter((el) => el.show) as DropdownMenuItem[];

  return (
    <div>
      <Dropdown
        trigger={['click']}
        overlay={<DropdownMenu key={id} menuItems={menuItems} headerTitle={LEASE} hasDivider />}
      >
        <Icon name={'dots-menu'} onClick={() => { }} />
      </Dropdown>
    </div>
  );
}

export default MasterAgreementActionCell