export enum CreditCheckResultEnum {
  Passed = 'passed',
  NotPassed = 'not passed',
  NA = 'N/A'
}

export const CreditCheckResultTitles = {
  [CreditCheckResultEnum.Passed]: 'Meets Criteria',
  [CreditCheckResultEnum.NotPassed]: 'Not Meets Criteria',
  [CreditCheckResultEnum.NA]: 'N/A'
}