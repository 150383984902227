import React from 'react'
import { CellProps } from 'react-table'

const StringCell = <T extends object>({ value }: CellProps<T, string>) => {
  return (
    <div>
        <p className="text-trueGray">
            {value}
        </p>
    </div>
  )
}

export default StringCell

