import { SortOrder } from "common/types/sort-order";
import SortOrderEnumParam from "query-params/sortOrderEnumParam";
import { createEnumParam, NumberParam, QueryParamConfig, useQueryParams, withDefault } from 'use-query-params';

export const LAST_UPDATED = 'Last updated';
export const PROPERTY = 'Property';
export const LANDLORD = 'Landlord';
export const TENANT = 'Tenant';
export const OFFER = 'Offer';
export const STATUS = 'Status';
export const FILE_DOWNLOAD = 'File download';
export const ACTIONS = 'Actions';

export const VIEW_MASTER_AGREEMENT = 'View master agreement';
export const VIEW_LEASE_DOCUMENTS = 'View lease documents';
export const LEASE = 'Lease';

export const LEASE_DOCUMENTS = 'Lease Documents';
export const MASTER_AGREEMENTS = 'Master Agreements';

export type MasterAgreementsQueryParamConfig = {
  sortBy: QueryParamConfig<'date'>;
  order: QueryParamConfig<SortOrder>;
  // NOTE: for filters
  // statuses: QueryParamConfig<`${OfferStatusDto}`[]>;
  page: QueryParamConfig<number>;
};

export const defaultQueryParamConfig: MasterAgreementsQueryParamConfig = {
  sortBy: withDefault(createEnumParam(['date']), 'date'),
  order: withDefault(SortOrderEnumParam, 'desc'),
  page: withDefault(NumberParam, 1),
};
