import { Modal } from 'components/organisms/Modal';
import { useWindowSize } from 'hooks';
import { useState } from 'react'
import { PreviewLeaseDocument } from 'components/organisms/PreviewLeaseDocument/PreviewLeaseDocument';
import SentLeaseDocument from '../AttachedLeaseModal/SentLeaseDocument';
import { Button } from 'legacy-components/componets';
import { Link } from 'react-router-dom';
import { SentLeaseDto } from '../../MasterAgreementsTable/types/master-agreements-table.type';

interface Props {
  onAccept: () => void;
  onGoBack: () => void;
  isOpen: boolean;
  leases: SentLeaseDto[];
  isAccepting: boolean;
}

const ConsentLeasesModal = ({ onAccept, onGoBack, isOpen, leases, isAccepting }: Props) => {
  const { isMobile } = useWindowSize();
  const [openPreviewModal, togglePreviewModal] = useState(false);
  const [checkboxLeases, setCheckboxLeases] = useState(leases);
  const [previewFileUrl, setPreviewFileUrl] = useState<string>('');

  const previewDocument = (fileUrl: string) => {
    togglePreviewModal(true);
    setPreviewFileUrl(fileUrl);
  }

  const handleCheckboxChange = (fileId: string, isChecked: boolean) => {
    setCheckboxLeases(checkboxLeases.map(x => x.fileId === fileId ? { ...x, isChecked } : x));
  }

  const isAllFilesChecked = () => {
    return !checkboxLeases.some(x => x.isChecked !== true);
  }

  return (
      <Modal
        title={'Read, Accept & Sign Lease Documents'}
        isOpen={isOpen}
        isFullScreen={isMobile}
        closable={false}
        contentClassName={'w-[800px]'}
      >
        <PreviewLeaseDocument open={openPreviewModal} onClose={() => togglePreviewModal(false)} file={previewFileUrl} />
          <div className='flex flex-col gap-6 text-base font-medium p-2'>
            <div className='flex justify-center'>
              Please click on each individual lease document provided. Take the time to carefully read through the terms and conditions outlined in 
              the document to ensure that you fully understand the obligations and responsibilities involved. Once you have reviewed the content 
              thoroughly, confirm your acceptance by clicking on the "Accept" button. After accepting all the documents, you will be prompted to 
              sign the Master Lease Agreement  electronically.
            </div>
            <div className='max-h-48 overflow-y-auto'>
              <SentLeaseDocument name='MasterAgreement' fileName='Landlord Master Agreement' withCheckbox={true} disabled={true} checked={true} />
              {checkboxLeases && checkboxLeases
                .map(file => (
                <SentLeaseDocument key={file.fileId}
                  name={file.fileId}
                  withCheckbox={true}
                  checked={file.isChecked}
                  fileName={`Review ${file.title}`}
                  fileUrl={file.fileUrl}
                  onChange={(e) => handleCheckboxChange(file.fileId, e.target.checked)}
                  onPreview={() => previewDocument(file.fileUrl)}/>
              ))}
            </div>
            <div>
              By clicking on Consent and Accept, you agree to our <Link to={'/terms-of-service'} className='text-primary'>Terms </Link> 
              and have read and acknowledge Landlord Specific Leases.
            </div>
            <div className='flex flex-end gap-2'>
              <Button label={'Consent & Accept'} theme={'primary'} onClick={onAccept} disabled={!isAllFilesChecked()} isLoading={isAccepting} />
              <Button label={'Go back'} theme={'secondary'} onClick={onGoBack} />
            </div>
          </div>
      </Modal>
  ) 
  // 
}

export default ConsentLeasesModal