import { CellProps } from 'react-table';
import { ApplicationsTableRow } from '../common/types/types';
import { DropdownMenu, Icon } from 'legacy-components/componets';
import { DropdownMenuItem } from 'legacy-components/dropdown-menu/common/types/dropdown-menu-item';
import { ApplicationsPayloadCell } from '../common/types/applications-table-row';
import { OfferStatusDto } from 'common/enums/services/api/offers/offer-status-dto.enum';

import Dropdown from 'rc-dropdown';

const OffersActionsMenuCell = ({ value }: CellProps<ApplicationsTableRow, ApplicationsPayloadCell>) => {
  const { actions, status } = value;

  const isNewStatus = status === OfferStatusDto.PreApplicationPaid;
  const isBackgroundCheckPageVisible = status === OfferStatusDto.PreApplicationPaid 
    || status === OfferStatusDto.CreditCheckCompleted 
    || status === OfferStatusDto.BackgroundCheckCompleted
    || status === OfferStatusDto.BackgroundCheckInitiated
    || status === OfferStatusDto.Reject
    || status === OfferStatusDto.BackgroundCheckRequested
    || status === OfferStatusDto.WaitingAllSignature
    || status === OfferStatusDto.LeaseSigned
    || status === OfferStatusDto.LandlordCountersignedLease;

  const menuItems: DropdownMenuItem[] = [
    actions?.onViewFullOffer && {
      id: 1,
      title: 'View full offer',
      onClick: () => (actions.onViewFullOffer ? actions.onViewFullOffer() : null),
      icon: 'file',
    },
    isNewStatus && {
      id: 2,
      title: 'Accept Pre-application',
      onClick: () => actions.onAccept(),
      icon: 'circle-checked-regular',
    },
    // TODO: Check whether we can use the same Reject for Pre-application and full application
    isNewStatus && {
      id: 3,
      title: 'Reject',
      onClick: () => actions.onReject(),
      icon: 'times-circle',
    },
    isNewStatus && {
      id: 4,
      title: 'Request background check',
      onClick: () => actions.onRequestBackgroundCheck(),
      icon: 'faEye',
    },
    isBackgroundCheckPageVisible && 
    {
      id: 5,
      title: 'View background check',
      onClick: () => actions.onViewBackgroundCheck(),
      icon: 'fa-money-check-dollar',
    },
     // TODO: need to handle Accept/Reject FULL application when status === BackgroundCheckCompleted
  ].filter(Boolean) as DropdownMenuItem[];

  return (
    <Dropdown trigger={['click']} overlay={<DropdownMenu menuItems={menuItems} headerTitle={'Offers'} />}>
      <Icon name={'dots-menu'} onClick={() => {}} />
    </Dropdown>
  );
};

export { OffersActionsMenuCell };
