import { TablePaginationProps } from 'common/types/components/ui/table/table-pagination-props.type';
import { useWindowSize } from 'hooks';
import useMasterAgreements from 'hooks/query/use-renter-lease-documents';
import React, { useMemo, useState } from 'react'
import { useQueryParams } from 'use-query-params';
import { MasterAgreementActionsCellProps, MasterAgreementTableAccessor, MasterAgreementTableDto, MasterAgreementTableRow, TableMode } from './types/master-agreements-table.type';
import { useNavigate } from 'react-router-dom';
import { AppRoute, EstateTypeDto, OfferStatusDto } from 'common/enums/enums';
import { generateDynamicPath } from 'helpers/routes-path';
import { TableColumn } from 'common/types/components/ui/table/table-props.type';
import { ACTIONS, defaultQueryParamConfig, LANDLORD, LAST_UPDATED, LEASE_DOCUMENTS, MasterAgreementsQueryParamConfig, PROPERTY, STATUS, TENANT } from './constants/master-agreements-table.constants';
import { OfferDateCell } from '../../../../legacy-pages/renter/profile/renter-profile/offers/components/OfferDateCell';
import { OffersPropertyCell } from '../../../../legacy-pages/renter/profile/renter-profile/offers/components/OfferPropertyCell';
import { CellProps } from 'react-table';
import { PropertyCellProps } from '../../../../legacy-pages/renter/profile/renter-profile/common/components/table/renter-table.types';
import StringCell from '../../../../legacy-pages/renter/profile/renter-profile/common/components/table/cells/StringCell';
import { getPricePerMonth } from '../../../../legacy-pages/renter/profile/renter-profile/offers/common/utils/OfferPage.utils';
import { OfferStatusCell } from '../../../../legacy-pages/renter/profile/renter-profile/offers/components/OfferStatusCell/OfferStatusCell';
import { Table } from 'legacy-components/componets';
import MasterAgreementActionCell from './components/RenterMasterAgreementActionCell';
import AttachedLeasesModal from '../organisms/AttachedLeaseModal/AttachedLeasesModal';

interface Props {
  mode: TableMode;
  queryParamConfig?: MasterAgreementsQueryParamConfig;
  title?: string;
}

const maxItemsPerPage = 5;

const MasterAgreements = ({ mode, queryParamConfig = defaultQueryParamConfig, title = LEASE_DOCUMENTS }: Props) => {
  const queryConfig = useMemo<MasterAgreementsQueryParamConfig>(() => queryParamConfig, []);
  const [openLeaseDocumentsModal, setOpenLeaseDocumentsModal] = useState(false);
  const [selectedApplicationId, setSelectedApplicationId] = useState<string>();
  const [query, setQuery] = useQueryParams(queryConfig);
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  
  const isRenterMode = mode === TableMode.Renter;

  const handleOpenLeaseDocumentsModal = (applicationId: string) => {
    setSelectedApplicationId(applicationId);
    setOpenLeaseDocumentsModal(true);
  }

  const {
    data: masterAgreements,
    isLoading: isMasterAgreementsLoading,
    isFetching: isMasterAgreementsFetching,
  } = useMasterAgreements(
    {
      page: query.page,
      perPage: maxItemsPerPage,
      sorters: { updatedAt: query.order },
    },
    {
      placeholderData: (previousValue) => previousValue,
    },
  );

  const total = masterAgreements?.totalCount || 0;

  const paginationCallback = React.useCallback((newPage: number) => {
    setQuery((prev) => ({ ...prev, page: newPage }));
  }, []);

  const paginationTableProps = React.useMemo<TablePaginationProps>(() => {
    return {
      currentPage: query.page,
      totalCount: total,
      pageSize: maxItemsPerPage,
      onPageChange: paginationCallback,
    };
  }, [query.page, total, maxItemsPerPage, paginationCallback]);
  
  const getActions = (item: MasterAgreementTableDto): MasterAgreementActionsCellProps => {
    return {
      onViewMasterAgreement: () => navigate(`${generateDynamicPath(isRenterMode ? AppRoute.RENTER_MASTER_AGREEMENT : AppRoute.LANDLORD_MASTER_AGREEMENT, { applicationId: item.applicationId })}`),
      onViewAttachedLeaseDocuments: () => handleOpenLeaseDocumentsModal(item.applicationId),
      id: item.id,
      status: item.applicationStatus,
      viewLeaseDocuments: item.hasSentLeases
    };
  };

  
  const formattedTableData = React.useMemo<MasterAgreementTableRow[]>(() => {
    return (
      masterAgreements?.items.map((item) => ({
        date: item?.updatedAt,
        property: {
          location: item?.estateAddress,
          price: item?.price,
          image: item?.thumbnailUrl,
          isMulti: item?.estateType === EstateTypeDto.MultiUnit,
          unitNumber: item?.unitNumber,
        },
        userName: isRenterMode ? item?.landLordName : item?.renterName,
        proposedRent: getPricePerMonth(item?.proposedRent),
        status: item?.applicationStatus,
        actions: getActions(item),
      })) || []
    );
  }, [masterAgreements, getActions]);

  // TODO: for mobile version, check offers.tsx
  const tableProps = {
    isLoading: isMasterAgreementsLoading,
    data: formattedTableData,
    paginationProps: {
      currentPage: query.page,
      totalCount: total,
      pageSize: maxItemsPerPage,
      onPageChange: paginationCallback,
    },
  };

  const columns: TableColumn<MasterAgreementTableRow>[] = [
    {
      Header: LAST_UPDATED,
      accessor: MasterAgreementTableAccessor.Date,
      Cell: OfferDateCell as React.FC<CellProps<MasterAgreementTableRow, Date>>,
      width: '15%',
      minWidth: 150,
      sorter: true,
      sortOrder: query.order,
      onSort: (sortOrder) => setQuery((prev) => ({ ...prev, page: 1, order: sortOrder })),
    },
    {
      Header: PROPERTY,
      accessor: MasterAgreementTableAccessor.Property,
      Cell: OffersPropertyCell as React.FC<CellProps<MasterAgreementTableRow, PropertyCellProps>>,
      width: '35%',
      minWidth: 300,
    },
    {
      Header: isRenterMode ? LANDLORD : TENANT,
      accessor: MasterAgreementTableAccessor.UserName,
      Cell: StringCell as React.FC<CellProps<MasterAgreementTableRow, string>>,
      width: '25%',
      minWidth: 160,
    },
    {
      Header: STATUS,
      accessor: MasterAgreementTableAccessor.Status,
      Cell: OfferStatusCell as React.FC<CellProps<MasterAgreementTableRow, OfferStatusDto>>,
      width: '15%',
      minWidth: 120,
    },
    {
      Header: ACTIONS,
      accessor: MasterAgreementTableAccessor.Actions,
      Cell: MasterAgreementActionCell,
      width: '10%',
      minWidth: 100,
    },
  ];

  const styleConfig = {
    header: {
      backgroundColor: '#F1FBFF',
      height: 54,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      marginBottom: 10,
    },
  };

  return (
    <>
      {selectedApplicationId && openLeaseDocumentsModal && <AttachedLeasesModal 
        applicationId={selectedApplicationId}
        isOpen={openLeaseDocumentsModal}
        onClose={() => setOpenLeaseDocumentsModal(false)}
      />}
      <div className='grow overflow-x-auto p-4 md:px-[34px] md:py-[28px] shadow-white-xl'>
        <div className='mb-2 md:mb-9'>
          <div className='profile-info-header flex justify-between items-center mb-2 md:mb-9'>
            <div className='title text-xl font-bold'>{title}</div>
          </div>
        </div>
        <div className='flex flex-col gap-9'>
          <Table
            styleConfig={styleConfig}
            data={formattedTableData || []}
            columns={columns}
            isLoading={isMasterAgreementsLoading || isMasterAgreementsFetching}
            paginationProps={paginationTableProps}
          />
        </div>
      </div>
    </>
  )
}

export default MasterAgreements