enum AppRoute {
  ROOT = '/',
  ESTATES = '/estates',
  ESTATES_GRID = '/estates/grid',
  ESTATES_MAP = '/estates/map',
  SIGN_IN = '/auth/sign-in',
  SIGN_UP = '/auth/sign-up',
  FORGOT_PASSWORD = '/auth/forgot-password',
  I_RENT = '/i-rent',
  SCHEDULER_TOUR_CHECKOUT = '/i-rent/schedule-tour/checkout',
  SCHEDULER_TOUR_CHECKOUT_$ID = '/i-rent/schedule-tour/checkout/:tourId',
  TOURS_$ID = '/i-rent/profile/tours/:tourId',
  OFFERS = '/offers',
  OFFER_PAYMENT = '/offer-payment',
  PRE_OFFER_PAYMENT = '/i-rent/offers/pre-application/checkout/:offerId',
  FULL_OFFER_PAYMENT = '/i-rent/offers/full-application/checkout/:offerId',
  RENTER_MASTER_AGREEMENT = '/i-rent/profile/master-agreements/:applicationId',
  LANDLORD_MASTER_AGREEMENT = '/i-landlord/profile/master-agreements/:applicationId',
  MASTER_AGREEMENTS = '/i-rent/profile/master-agreements',
  ANY = '*',
  RENTER_OFFERS = '/i-rent/profile/offers',
  LANDLORD_LISTINGS = '/i-landlord/profile/listing',
  LANDLORD_LEASE_DOCUMENTS = '/i-landlord/profile/lease-documents',
  PROFILE_INFO = '/i-rent/profile/info/personal',
  LANDLORD_PROFILE_INFO = '/i-landlord/profile/info/personal',
}

export { AppRoute };
