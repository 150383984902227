import {
  CreateLeaseDocument,
  LeaseDocumentResponse,
  SendLeaseDocument,
} from 'common/types/services/api/landlord-lease-documents/landlord-lease-documents.types';
import { PaginationDtoRequest } from 'common/types/types';
import qs from 'qs';
import { axiosInstance } from './axiosInstance';
import { LeaseDocumentsApiPath } from 'common/enums/enums';
import { ApplicantSignature, MasterAgreementApplicant, MasterAgreementInfo, SignMasterAgreementResult } from 'legacy-pages/renter/profile/renter-profile/master-agreements/common/types/master-agreement.type';
import { MasterAgreementsDto, MasterAgreementsRequestDto, SentLeaseConsentDto, SentLeaseDto } from 'components/templates/MasterAgreement/MasterAgreementsTable/types/master-agreements-table.type';

export const getLeaseDocuments = (query?: PaginationDtoRequest): Promise<LeaseDocumentResponse> => {
  const queryString = qs.stringify({
    page: query?.page,
    perPage: query?.perPage,
  });
  return axiosInstance.get(`${LeaseDocumentsApiPath.LANDLORD_LEASE_DOCUMENTS}?${queryString}`);
};

export const createLeaseDocument = async (request: CreateLeaseDocument[]): Promise<string[]> => {
  const filesIds: string[] = [];

  for (let document of request) {
    const formData = new FormData();
    formData.append('file', document?.file);
    formData.append('title', document?.title);
    const fileId = await axiosInstance.post<void, string>(`${LeaseDocumentsApiPath.LANDLORD_LEASE_DOCUMENTS}`, formData);
    filesIds.push(fileId);
  }

  return filesIds;
};

export const deleteLeaseDocument = (id: string): Promise<boolean> => {
  return axiosInstance.delete(`${LeaseDocumentsApiPath.LANDLORD_LEASE_DOCUMENTS}/${id}`);
};

export const sendLeaseDocuments = (request: SendLeaseDocument): Promise<void> => {
  return axiosInstance.post(`${LeaseDocumentsApiPath.LANDLORD_LEASE_DOCUMENTS}/send`, request);
}

export const getMasterAgreement = (applicationId: string): Promise<MasterAgreementInfo> => {
  return axiosInstance.get(`${LeaseDocumentsApiPath.MASTER_AGREEMENT}/${applicationId}`);
};

export const signMasterAgreement = (signature: ApplicantSignature): Promise<SignMasterAgreementResult> => {
  return axiosInstance.post(`${LeaseDocumentsApiPath.MASTER_AGREEMENT}/sign`, signature);
};

export const countersignMasterAgreement = (signature: ApplicantSignature): Promise<void> => {
  return axiosInstance.post(`${LeaseDocumentsApiPath.MASTER_AGREEMENT}/countersign`, signature);
};

export const getMasterAgreementApplicant = (applicantId: string): Promise<MasterAgreementApplicant> => {
  return axiosInstance.get(`${LeaseDocumentsApiPath.MASTER_AGREEMENT}/applicant/${applicantId}`);
};

export const getMasterAgrements = (query: MasterAgreementsRequestDto): Promise<MasterAgreementsDto> => {
  const queryString = qs.stringify(
    {
      page: query.page,
      perPage: query.perPage,
      sorters: query.sorters,
    },
    { encode: false, arrayFormat: 'repeat' },
  );
  return axiosInstance.get(`${LeaseDocumentsApiPath.MASTER_AGREEMENT}?${queryString}`);
};

export const getLandlordMasterAgrements = (query: MasterAgreementsRequestDto): Promise<MasterAgreementsDto> => {
  const queryString = qs.stringify(
    {
      page: query.page,
      perPage: query.perPage,
      sorters: query.sorters,
    },
    { encode: false, arrayFormat: 'repeat' },
  );
  return axiosInstance.get(`${LeaseDocumentsApiPath.MASTER_AGREEMENT}/landlord?${queryString}`);
};

export const getSentLeases = (applicationId: string): Promise<SentLeaseDto[]> => {
  return axiosInstance.get(`${LeaseDocumentsApiPath.SENT_LEASES}/${applicationId}`);
};

export const signSentLeases = (consent: SentLeaseConsentDto): Promise<void> => {
  return axiosInstance.post(`${LeaseDocumentsApiPath.SENT_LEASES}/sign`, consent);
};
