import { useEffect, useState } from 'react'
import TenantScreeningServicePlan from './TenantScreeningServicePlanDialog'
import PayTenantBackgroundScreeningDialog from './PayTenantBackgroundScreeningDialog'
import { ScreeningServicePlan, ScreeningServicePlanPayment } from 'common/types/services/api/tenant-screening';
import { useGetOrderPlans } from 'hooks/query/tenant-screening';
import useRequestBackgroundCheck from 'hooks/query/offers/use-request-background-check';
import { openNotificationModal } from 'store/use-notification-modal-store';
import { NotificationType } from 'common/enums/enums';
import { useRequestBackgroundCheckPayment } from 'hooks/query/tenant-screening/useRequestBackgroundCheckPayment';
import { OffersItemDto } from 'common/types/types';

interface Props {
  offer?: OffersItemDto;
  onClose: () => void;
  isOpen: boolean;
  location: string;
  setLocation: (location: string) => void;
}

const RequestTenantScreeningDialog = ({ offer, onClose, isOpen, location, setLocation }: Props) => {
  const [servicePlanSelected, setServicePlanSelected] = useState<ScreeningServicePlan | undefined>();
  const [openPayScreeningServiceDialog, setOpenPayScreeningServiceDialog] = useState(false);
  const [screeningPaymentContext, setScreeningPaymentContext] = useState<ScreeningServicePlanPayment | undefined>();
  const { data: orderPlans, isPending: orderPlansLoading } = useGetOrderPlans(location, { enabled: !!location});

  useEffect(() => {
    if(servicePlanSelected) {
      requestBgCheckPayment({
        screeningType: 1,
        orderPlanId: servicePlanSelected.id,
        amount: servicePlanSelected.price,
        tenantId: offer?.renterId ?? ''
      });
    }
    
  }, [servicePlanSelected]);

  const { mutate: requestBackgroundCheck, isPending: isRequestBackgroundCheckPending } = useRequestBackgroundCheck({
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: e?.response?.data?.detail ?? 'Background check cannot be requested, try again later.',
      });
    },
  });

  const { mutate: requestBgCheckPayment, isPending: pendingBgCheckPayment } = useRequestBackgroundCheckPayment({
    onSuccess: data => handlePaymentContextLoaded(data),
    onError: (e) => {
      openNotificationModal({
        type: NotificationType.ERROR,
        title: 'Failed to process background check payment. Try again later.',
      });
    }
  });

  const handlePaymentContextLoaded = (context: ScreeningServicePlanPayment) => {
      if(context) {
        setScreeningPaymentContext(context);
      }      
  }

  const handleCloseScreeningServicePlanDialog = () => {
    onClose();
    setLocation('');
  }

  const handleSelectScreeningServicePlan = (plan, idx) => {
    setServicePlanSelected(plan);

    if (isOpen) {
        onClose();
    }
    
    if(!openPayScreeningServiceDialog) {
      setOpenPayScreeningServiceDialog(!openPayScreeningServiceDialog);
    }
  }

  const handleScreeningPaymentSucceeded = () => {
    handleClosePayScreeningServicePlanDialog();

    if(offer?.id) {
      requestBackgroundCheck(offer.id);
    }
  }

  const handleClosePayScreeningServicePlanDialog = () => {
    if(openPayScreeningServiceDialog) {
      setOpenPayScreeningServiceDialog(!openPayScreeningServiceDialog);
    }
  }

  return (
    <>
      <TenantScreeningServicePlan 
        title="Background Check Service Plan"
        isOpen={isOpen}
        isLoading={orderPlansLoading} 
        data={orderPlans}
        onClose={handleCloseScreeningServicePlanDialog}
        onSelect={handleSelectScreeningServicePlan}
      />
      {openPayScreeningServiceDialog && <PayTenantBackgroundScreeningDialog 
        title="Pay for Background Check"
        isLoading={pendingBgCheckPayment}
        isOpen={openPayScreeningServiceDialog} 
        data={screeningPaymentContext}
        onSuccess={handleScreeningPaymentSucceeded}
        onClose={handleClosePayScreeningServicePlanDialog}        
      />}
    </>
  )
}

export default RequestTenantScreeningDialog
