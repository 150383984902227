import { Modal } from 'components/organisms/Modal';
import { useWindowSize } from 'hooks';
import { useGetSentLeaseDocuments } from 'hooks/query/use-renter-lease-documents';
import { useEffect, useState } from 'react';
import SentLeaseDocument from './SentLeaseDocument';
import { Button, Loader } from 'legacy-components/componets';
import { PreviewLeaseDocument } from 'components/organisms/PreviewLeaseDocument/PreviewLeaseDocument';
import { SentLeaseDto } from '../../MasterAgreementsTable/types/master-agreements-table.type';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  withGoBackButton?: boolean;
  applicationId: string;
}

const AttachedLeasesModal = ({ onClose, isOpen, applicationId }: Props) => {
  const { isMobile } = useWindowSize();
  const { data: leases, isLoading} = useGetSentLeaseDocuments(applicationId);
  const [leaseDocuments, setLeaseDocuments] = useState<SentLeaseDto[]>([]);
  const [openPreviewModal, togglePreviewModal] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState<string>('');

  useEffect(() => {
    if (!leases) return;
    setLeaseDocuments(leases);
  }, [leases]);

  const previewDocument = (fileUrl: string) => {
    togglePreviewModal(true);
    setPreviewFileUrl(fileUrl);
  }

  return (
      <Modal
        title={'Additional lease documents'}
        isOpen={isOpen}
        isFullScreen={isMobile}
        contentClassName={'w-[650px]'}
        onOpenChange={(open) => {
          if (open) return;
          onClose();
        }}
      >
        {isLoading ? <Loader/> : 
        <>
          <PreviewLeaseDocument open={openPreviewModal} onClose={() => togglePreviewModal(false)} file={previewFileUrl} />
          <div className='flex flex-col gap-6 text-base font-medium p-2'>
            <div className='flex justify-center'>
              <p>Lease documents that landlord added to the Master Agreement</p>
            </div>
            <div className='max-h-48 overflow-y-auto'>
              {leaseDocuments && leaseDocuments
                .map(file => (
                <SentLeaseDocument key={file.fileId}
                  fileName={`Review ${file.title}`}
                  fileUrl={file.fileUrl}
                  onPreview={() => previewDocument(file.fileUrl)}/>
              ))}
            </div>
            <Button label={'Close'} theme={'primary'} onClick={onClose} />
          </div>
        </>}
      </Modal>
  )
}

export default AttachedLeasesModal