export const extractStateFromAddress = (address?: string): string => {
  const maxChunkIndex: number = 3;
  const stateLength: number = 2;
  const stateIndex: number  = 2;
  
  if(typeof address !== 'string' || !address)
    return '';

  let chunks = address.split(',')
    .map(e => e.trim());  

  if(chunks.length < maxChunkIndex || chunks[stateIndex].length !== stateLength) 
      return '';

  return chunks[stateIndex];
};