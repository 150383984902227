import clsx from "clsx";
import { CreditCheckResultEnum } from "./credit-score-report.types";

const commonStatusStyle = 'font-medium leading-tight px-[10px] py-2 rounded-3xl text-center max-w-[160px]';
const textStyles = 'text-xs md:text-sm lg:text-[15px]'

export const creditCheckSearchStyles: Record<CreditCheckResultEnum, string> = {
  [CreditCheckResultEnum.Passed]: clsx('bg-[#F1FFF5] text-[#A0D911]', commonStatusStyle, textStyles),
  [CreditCheckResultEnum.NotPassed]: clsx('bg-[#FEF2F2] text-[#FF385C]', commonStatusStyle, textStyles),
  [CreditCheckResultEnum.NA]: clsx('bg-[#FFFBEB] text-[#F59E0B]', commonStatusStyle, textStyles)
}