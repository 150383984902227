import { MASTER_AGREEMENTS } from "components/templates/MasterAgreement/MasterAgreementsTable/constants/master-agreements-table.constants"
import MasterAgreements from "components/templates/MasterAgreement/MasterAgreementsTable/MasterAgreements"
import { TableMode } from "components/templates/MasterAgreement/MasterAgreementsTable/types/master-agreements-table.type"

const LandlordMasterAgreements = () => {
  return (
    <MasterAgreements mode={TableMode.Landlord} title={MASTER_AGREEMENTS}/>
  )
}

export default LandlordMasterAgreements
