export const SECURITY_DEPOSIT = 'Security Deposit';
export const AXXELISTFEE = 'Axxelist Fee';
export const OTHER_FEE = 'Other Fees';
export const FIRST_MONTH = 'First Month';
export const LAST_MONTH = 'Last Month';
export const MOVEIN = 'Move In';
export const LEASE_TERMS = 'Lease term';

export const LEASE_TERMS_VALUE = (months: number) => `min  ${months} months`;

export const RENT = 'Rent';
export const PERMONTH = '/mo';

export const DOLLAR = '$';