import { BackgroundCheckReportDto } from '../../types';
import { backgroundCheckSearchResultSection, backgroundCheckSearchStyles } from './background-check-search.styles';
import clsx from 'clsx';
import { BackgroundCheckSearchEnum } from './background-check-search.enum';

interface Props {
  report?: BackgroundCheckReportDto;
  isBlurred?: boolean;
}

const BackgroundCheckReport = ({ report, isBlurred = false }: Props) => {
  const getStatusStyles = (status?: BackgroundCheckSearchEnum) => {
    if (!status) return backgroundCheckSearchStyles[BackgroundCheckSearchEnum.NA];
    return isBlurred ? backgroundCheckSearchStyles[BackgroundCheckSearchEnum.NA] : backgroundCheckSearchStyles[status];
  };
  
  return (
    <div>
      <h4 className={clsx('text-xl font-bold leading-[2.25]', backgroundCheckSearchResultSection)}>Background Report</h4>
      <div className={clsx('flex justify-between', backgroundCheckSearchResultSection)}>
        <p className='leading-[2.25]'>Eviction Search:</p>
        <p style={{maxHeight: 'max-content'}} className={getStatusStyles(report?.evictions)}>{report?.evictions || BackgroundCheckSearchEnum.NA}</p>
      </div>
      <div className={clsx('flex justify-between', backgroundCheckSearchResultSection)}>
        <p className='leading-[2.25]'>Sex Offender Search:</p>
        <p style={{maxHeight: 'max-content'}} className={getStatusStyles(report?.offenders)}>{report?.offenders || BackgroundCheckSearchEnum.NA}</p>
      </div>
      <div className={clsx('flex justify-between', backgroundCheckSearchResultSection)}>
        <p className='leading-[2.25]'>Global Watchlist Search:</p>
        <p style={{maxHeight: 'max-content'}} className={getStatusStyles(report?.watchlist)}>{report?.watchlist || BackgroundCheckSearchEnum.NA}</p>
      </div>
      <div className='flex justify-between px-5 py-2'>
        <p className='leading-[2.25]'>National Criminal Search:</p>
        <p style={{maxHeight: 'max-content'}} className={getStatusStyles(report?.criminals)}>{report?.criminals || BackgroundCheckSearchEnum.NA}</p>
      </div>
    </div>
  )
}

export default BackgroundCheckReport